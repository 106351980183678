.categories_page {
  font-family: 'poppins';
  padding-top: 100px;
  padding-bottom: 50px;
}

.categories_page .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9da1a3;
  display: inline-block;
}

.categories_page .button-label {
  display: block;
}

.categories_page .icon-outer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.categories_page .icon-outer ion-icon {
  font-size: 25px;
  color: white;
}

.categories_page .color-outer {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.categories_page .color-inner {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #dfe6ea;
}

.categories_page .color-select {
  text-align: center;
  padding: 20px 0;
  width: 200px;
  height: 250px;
  overflow: auto;
}

.categories_page .color-select .color {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: red;
  margin: 5px;
}

.categories_page .color-select .color.active {
  outline: blue solid 2px;
}

.categories_page .icon-select {
  width: 200px;
  padding: 20px 0px;
  height: 250px;
  overflow: auto;
}

.categories_page .icon-select .icon-handle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px 10px;
}

.categories_page .icon-select .icon-handle .cat-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightgray;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 10px;
}

.categories_page .icon-select .icon-handle .cat-icon ion-icon {
  font-size: 30px;
  color: black;
}

.categories_page .color-btn {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: #dfe6ea 1px solid;
}

.categories_page .icon-btn {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: #dfe6ea 1px solid;
}

.categories_page input {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  font-family: 'poppins';
}

.categories_page .category-select {
  margin-top: 31px;
}

.categories_page .category-select .category-btn {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #dfe6ea;
  border: none;
}

.categories_page .btn-grey {
  background-color: #dfe6ea;
}

.categories_page .dropdown-toggle {
  font-size: 30px;
}

.categories_page .cat-section .cat-table table .img-sec {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d61313;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.categories_page .cat-section .cat-table table .img-sec ion-icon {
  font-size: 30px;
  color: white;
}

.categories_page .cat-section .cat-table table .btn-edit {
  background-color: #dfe6ea;
}

.categories_page .cat-section .cat-table table .btn-edit .edit-btn ion-icon {
  font-size: 40px;
  color: #272828;
}

.categories_page .cat-section .cat-table table .btn-delete {
  background-color: #dfe6ea;
}

.categories_page .cat-section .cat-table table .btn-delete .del-btn ion-icon {
  font-size: 30px;
  color: #272828;
}
