body {
  background: #F2F5F9;
}

.navigationBar {
  -webkit-box-shadow: 0px 5px 15px 5px #ece3e3;
          box-shadow: 0px 5px 15px 5px #ece3e3;
}

.navigationBar h3 {
  padding-left: 60px;
  color: black;
}

.navigationBar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.navigationBar .user-name {
  font-size: 20px;
  color: black;
  margin-top: 13px;
}

.container .card {
  -webkit-box-shadow: 0px 0px 20px 7px #e5dada;
          box-shadow: 0px 0px 20px 7px #e5dada;
  border-radius: 15px;
}

.container .card .card-body h5 {
  font-size: 16px;
  margin-top: 8px;
}

.container .rightContent .top-card {
  padding: 14px;
  cursor: pointer;
}

.container .rightContent .top-card p {
  padding: 0;
  margin: 0;
  font-size: 20px;
  text-decoration: none;
  color: black;
}

.container .rightContent .bottom-card {
  margin-top: 30px;
  padding: 14px;
}

.container .rightContent .bottom-card p {
  padding: 0;
  margin: 0;
  font-size: 20px;
  text-decoration: none;
}

.container .rightContent .bottom-card p a {
  text-decoration: none;
  color: black;
}

.transactionSection .card {
  margin-bottom: 30px;
}

.transactionSection .card h2 {
  font-size: 18px;
  font-weight: 400;
}

.transactionSection .card h1 {
  font-size: 12px;
}

.transactionSection .card .column1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.transactionSection .card .column1 .imgsec {
  background-color: #2BAF62;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}

.transactionSection .card .column2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.transactionSection .card .column2 .imgsec {
  background-color: #2B40AF;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}

.transactionSection .icon-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.transactionSection .icon-container .icon-holder {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2B40AF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 15px;
}

.transactionSection .icon-container .icon-holder ion-icon {
  font-size: 20px;
  color: white;
  margin: 0;
  padding: 0;
}

.transactionSection .icon-container h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.transactionSection .transaction-title {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.transactionSection .amount {
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}

.addwalletPopup input {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 0;
}

.addwalletPopup button {
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-size: 16px;
  background-color: #dfe6ea;
  border-radius: 0;
}
