.fullbackground-section {
    display: flex;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    .left-section {
        width: 50%;
        height: 100%;
        h3 {
            font-size: 32px;
            font-weight: 600;
        }
        p {
            font-size: 16px;
            font-weight: 500;
        }
        .main-login-form {
            margin-top: 120px;
            input {
                border: 1px solid #cfcfcf;
                background: #FFFFFF;
                padding: 30px 20px;
                border-radius: none;
                outline: none;
            }
            p a {
                color: #000000;
                font-size: 17px;
                font-weight: 400;
                text-decoration: none;
            }
            .submit {
                font-size: 18px;
                font-weight: 600;
                background: #000000;
                color: white;
            }
            .btn {
                border-radius: none;
                font-size: 18px;
                font-weight: 600;
                padding: 10px 20px;
                text-decoration: none;
                outline: none;
                border: 1px solid black;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
            .para {
                color: #000;
                font-size: 18px;
                font-weight: 600;
                .name {
                    color: #32396e;
                    cursor: pointer;
                }
            }
        }
    }
    .right-section {
        width: 50%;
        background-image: url('./background.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
    }
    
    @media only screen and (max-width: 600px) {
        height: auto;
        flex-direction: column;

        .left-section {
            width: 100%;
        }
        .right-section {
           display: none;
        }
    }
}
