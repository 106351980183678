
.navigationBar{
    box-shadow: 0px 5px 15px 5px #ece3e3;
    
        h3{
            padding-left: 60px;
            color: black;
        }
        img{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: -50px;
        }
        .user-name{
            font-size: 20px;
            color: black;
            margin-left: 5px;
            margin-top: 13px;
            // padding-right: 60px;
        }
        .dropMenu{
   
        margin-left:- 60px;
        }
        .dropdown-menu.show {
            left: -25px;
        }
        nav{
            .navbar-nav{

                .nav-item{
                    &.active{
                        .nav-link{

                            color:#000;
                            font-weight:500;
                        }
                    }
                }
            }
        }
}


.btn-event {
    background-color: #dfe6f0;
}



/* width */
::-webkit-scrollbar {
    width: 0;
  }


  body{
      font-family: 'poppins',sans-serif;
  }