.categories_page {
  font-family: 'poppins';
  padding-top: 100px;
  padding-bottom: 50px;
  // height: 100vh;

  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(157, 161, 163);
    display: inline-block;
  }

  .button-label {
    display: block;
  }

  .icon-outer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
      ion-icon{
         font-size: 25px;
         color: rgb(255, 255, 255);
         
      }
 
  }

  

  .color-outer {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .color-inner {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgb(223,230,234);
  }

  .color-select {
    text-align: center;
    padding: 20px 0;
    width: 200px;
    height: 250px;
    overflow: auto;
    .color {
      display: inline-block;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: red;
      margin: 5px;

      &.active {
        outline: blue solid 2px;
      }
    }
  }

  .icon-select {
    width: 200px;
    padding: 20px 0px;
    height: 250px;
    overflow: auto;
    // margin-top: 180px;

    .icon-handle {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;

      .cat-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: lightgray;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        ion-icon {
          font-size: 30px;
          color:black;
        
        }
      }
    }
  }

  // .btn {
  //   height: 60px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border: rgb(182, 181, 181) 1px solid;
  // }

  .color-btn {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rgb(223, 230, 234) 1px solid;
  }

  .icon-btn {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: rgb(223, 230, 234) 1px solid;
  }

  input {
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 16px;
    font-family: 'poppins';
  }

  .category-select {
    margin-top: 31px;

    .category-btn {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(223, 230, 234);
      border: none;
    }
  }
  .btn-grey{
    background-color: rgb(223, 230, 234);
  }

  .dropdown-toggle {
    font-size: 30px;
  }

  .cat-section {
    .cat-table {
      table {
        .img-sec {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgb(214, 19, 19);
          display: flex;
          align-items: center;
          justify-content: center;

          ion-icon {
            font-size: 30px;
            color:white;
          }


        }

        .btn-edit {
          background-color: rgb(223, 230, 234);

          .edit-btn {
            ion-icon {
              font-size: 40px;
              color: rgb(39, 40, 40);
            }
          }
        }

        .btn-delete {
          background-color: rgb(223, 230, 234);

          .del-btn {

            ion-icon {
              font-size: 30px;
              color: rgb(39, 40, 40);
            }
          }
        }
      }
    }

  }
}
