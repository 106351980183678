.navigationBar {
  -webkit-box-shadow: 0px 5px 15px 5px #ece3e3;
          box-shadow: 0px 5px 15px 5px #ece3e3;
}

.navigationBar h3 {
  padding-left: 60px;
  color: black;
}

.navigationBar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: -50px;
}

.navigationBar .user-name {
  font-size: 20px;
  color: black;
  margin-left: 5px;
  margin-top: 13px;
}

.navigationBar .dropMenu {
  margin-left: -60px;
}

.navigationBar .dropdown-menu.show {
  left: -25px;
}

.navigationBar nav .navbar-nav .nav-item.active .nav-link {
  color: #000;
  font-weight: 500;
}

.btn-event {
  background-color: #dfe6f0;
}

/* width */
::-webkit-scrollbar {
  width: 0;
}

body {
  font-family: 'poppins',sans-serif;
}
