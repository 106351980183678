.fullbackground-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.fullbackground-section .left-section {
  width: 50%;
  height: 100%;
}

.fullbackground-section .left-section h3 {
  font-size: 32px;
  font-weight: 600;
}

.fullbackground-section .left-section p {
  font-size: 16px;
  font-weight: 500;
}

.fullbackground-section .left-section .main-login-form {
  margin-top: 120px;
}

.fullbackground-section .left-section .main-login-form input {
  border: 1px solid #cfcfcf;
  background: #FFFFFF;
  padding: 30px 20px;
  border-radius: none;
  outline: none;
}

.fullbackground-section .left-section .main-login-form p a {
  color: #000000;
  font-size: 17px;
  font-weight: 400;
  text-decoration: none;
}

.fullbackground-section .left-section .main-login-form .submit {
  font-size: 18px;
  font-weight: 600;
  background: #000000;
  color: white;
}

.fullbackground-section .left-section .main-login-form .btn {
  border-radius: none;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  text-decoration: none;
  outline: none;
  border: 1px solid black;
}

.fullbackground-section .left-section .main-login-form .btn img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.fullbackground-section .left-section .main-login-form .para {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.fullbackground-section .left-section .main-login-form .para .name {
  color: #32396e;
  cursor: pointer;
}

.fullbackground-section .right-section {
  width: 50%;
  background-image: url("./background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .fullbackground-section {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .fullbackground-section .left-section {
    width: 100%;
  }
  .fullbackground-section .right-section {
    display: none;
  }
}
