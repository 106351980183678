.addWalletsection{

input{
    border-radius: 5px;
}
button{
    border-radius: 3px;
    margin-top: 31px;
}

}