.popUp input {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 0;
}

.popUp button {
  width: 100%;
  height: 60px;
  font-size: 16px;
  color: black;
  font-size: 16px;
  background-color: #dfe6ea;
  border-radius: 0;
}
