.profileSettings .photo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.profileSettings .photo-container img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.profileSettings .photo-container .uploadBtn {
  background-color: #dbe9db;
}

.profileSettings .photo-container .trashBtn {
  color: #d55d5d;
  background-color: #ceb2b2;
}

.accountForm label {
  font-size: 18px;
}

.accountForm input, .accountForm select {
  height: 60px;
  font-size: 20px;
  border-radius: 0;
}

.accountForm .updateBtn {
  height: 60px;
  font-size: 20px;
  margin-bottom: 40px;
  border-radius: 0;
  background-color: #dfe6f0;
  border: none;
  color: black;
}
