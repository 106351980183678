.transactionSection{

    .btn-event{
        background-color: rgb(223,230,240);

        ion-icon{
            font-size: 25px;

        }
    }

}
