.counter-container {




    .card {
        height: 100px;
        text-align: center;
        cursor: pointer ;
        margin: 10px 0;

        i {
            color: white;
            font-size: 25px !important;
        }

        h5 {
            color: white;
            font-size: 23px !important;

        }
    }

}

.addTransaction {

    .categoryBtn {
        width: 100%;
        background-color: rgb(223, 230, 234);
        i {
            color: rgb(125, 115, 115);
            font-size: 20px;
           
    
        }
    
        span {
            font-size: 16px;
            margin-left: 10px;
        }
    }

    
}

.dropdownSize {
    width: 230px;
    height: 200px;
    margin-top: 15px;
    overflow: auto;


    .circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ff0000;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;

        >* {
            display: block;
            font-size: 20px;
            color:white;
        }
       
    }
}


    .transactionBtn, .friendsSection {
         background-color: rgb(223, 230, 234);
    }
    












.transactionSection {
    font-family: 'Poppins', sans-serif;
    .no-found-desc-text{
        margin-top:-30px;
    }
    .table td,
    .table th {
        vertical-align: middle;

    }

    
    .card {
        box-shadow: 0px 0px 20px 7px #e5dada;
        max-height: 400px;
        overflow-y: auto;
        margin-bottom: 30px;
        tbody{
            margin-bottom: 0;
        }
        // padding: 0px 40px 0px 0px;
        // padding:0 25px;

        h2 {
            font-size: 18px;
            font-weight: 400;
        }

        h1 {
            font-size: 18px;
        }






    }




    .img-category {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #e5dada;

     ion-icon{
         font-size: 20px;
         color: white;
     }

    

    }

    p {
        font-size: 16px;
        margin: 0;
    }

    h5 {
        font-weight: 600;
    }
}


.category-icon{
  
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icon-holder{
            height:40px;
            width:40px;
            border-radius:50%;
            background-color:#2B40AF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right:15px;
            ion-icon{
                font-size:20px;
                color:white;
                margin:0;
                padding:0;
            }
        }
        h3{
            font-size:18px;
            font-weight:500;
            margin:0;
            padding:0;
        }
    
}