.profileSettings{

    .photo-container{
    display:flex;
    align-items:center;
    
    
        img{
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
    .uploadBtn{
    background-color: rgb(219, 233, 219);
    }
    .trashBtn{
    color:rgb(213, 93, 93);
    background-color: rgb(206, 178, 178);
    }
    }
    
    }
    
    .accountForm{
    
    label{
        font-size: 18px;
    }
    
        input, select{
            height:60px;
            font-size: 20px;
            border-radius: 0;
        }
    
        .updateBtn{
            height: 60px;
            font-size: 20px;
            margin-bottom: 40px;
            border-radius: 0;
            background-color: rgb(223, 230, 240);
            border: none;
            color: rgb(0, 0, 0);
        }
    
    }