.counter-container .card {
  height: 100px;
  text-align: center;
  cursor: pointer;
  margin: 10px 0;
}

.counter-container .card i {
  color: white;
  font-size: 25px !important;
}

.counter-container .card h5 {
  color: white;
  font-size: 23px !important;
}

.addTransaction .categoryBtn {
  width: 100%;
  background-color: #dfe6ea;
}

.addTransaction .categoryBtn i {
  color: #7d7373;
  font-size: 20px;
}

.addTransaction .categoryBtn span {
  font-size: 16px;
  margin-left: 10px;
}

.dropdownSize {
  width: 230px;
  height: 200px;
  margin-top: 15px;
  overflow: auto;
}

.dropdownSize .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ff0000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.dropdownSize .circle > * {
  display: block;
  font-size: 20px;
  color: white;
}

.transactionBtn, .friendsSection {
  background-color: #dfe6ea;
}

.transactionSection {
  font-family: 'Poppins', sans-serif;
}

.transactionSection .no-found-desc-text {
  margin-top: -30px;
}

.transactionSection .table td,
.transactionSection .table th {
  vertical-align: middle;
}

.transactionSection .card {
  -webkit-box-shadow: 0px 0px 20px 7px #e5dada;
          box-shadow: 0px 0px 20px 7px #e5dada;
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 30px;
}

.transactionSection .card tbody {
  margin-bottom: 0;
}

.transactionSection .card h2 {
  font-size: 18px;
  font-weight: 400;
}

.transactionSection .card h1 {
  font-size: 18px;
}

.transactionSection .img-category {
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  background-color: #e5dada;
}

.transactionSection .img-category ion-icon {
  font-size: 20px;
  color: white;
}

.transactionSection p {
  font-size: 16px;
  margin: 0;
}

.transactionSection h5 {
  font-weight: 600;
}

.category-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.category-icon .icon-holder {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #2B40AF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 15px;
}

.category-icon .icon-holder ion-icon {
  font-size: 20px;
  color: white;
  margin: 0;
  padding: 0;
}

.category-icon h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
