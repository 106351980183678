body {
    background: #F2F5F9;
}
.navigationBar {
    box-shadow: 0px 5px 15px 5px #ece3e3;
    h3 {
        padding-left: 60px;
        color: black;
    }
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        // margin-left: -50px;
    }
    .user-name {
        font-size: 20px;
        color: black;
        // margin-left: 20px;
        margin-top: 13px;
        // padding-right: 60px;
    }
}
.container {
    .card {
        box-shadow: 0px 0px 20px 7px #e5dada;
        border-radius: 15px;
        .card-body {
            h5 {
                font-size: 16px;
                margin-top: 8px;
            }
        }
    }
    .rightContent {
        .top-card {
          padding: 14px;
          cursor: pointer;
            p{
                padding: 0;
                margin: 0;
                font-size: 20px;
                text-decoration: none;
                color: black;
            }
        }
        .bottom-card {
          margin-top: 30px;
            padding: 14px;
            p {
                padding: 0;
                margin: 0;
                font-size: 20px;
                text-decoration: none;
                

                a{
                    text-decoration: none;
                    color: black;
                }
            }
         
        }
    }
}
.transactionSection {
    .card {
        margin-bottom: 30px;
        h2 {
            font-size: 18px;
            font-weight: 400;
        }
        h1 {
            font-size: 12px;
        }
        .column1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .imgsec {
                background-color: #2BAF62;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
        .column2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .imgsec {
                background-color: #2B40AF;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
            }
        }
    }
}


.transactionSection{
    .icon-container{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icon-holder{
            height:40px;
            width:40px;
            border-radius:50%;
            background-color:#2B40AF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right:15px;
            ion-icon{
                font-size:20px;
                color:white;
                margin:0;
                padding:0;
            }
        }
        h3{
            font-size:18px;
            font-weight:500;
            margin:0;
            padding:0;
        }
    }
    .transaction-title{
        font-size:16px;
        font-weight:400;
        text-align: left;
    }
    .amount{
        font-size:16px;
        font-weight:600;
        text-align: right;
    }

}
.addwalletPopup{

    input {
        width: 100%;
        height: 60px;
        border-radius: 5px;
        padding: 5px 20px;
        font-size: 16px;
       border-radius: 0;
}
button{
    width: 100%;
    height: 60px;
    font-size: 16px;
   
    font-size: 16px;
    background-color: rgb(223, 230, 234);
    border-radius: 0;
  
  }
}
